import * as React from "react"
import * as styles from "../../css/styles.scss"
import "./tours.scss"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

import Layout from "../../components/details-layout"
import Seo from "../../components/seo"


import Tours from "./components/listings"



const TourListings = () => (
  <Layout>
    <Seo title="Belize Inland Tours | Belize Inland Tours All Adventures " />
    <Tours/>
  </Layout>
)

export default TourListings;
